const baseUrl = 'https://www.wordvector.com/'
// const baseUrl = 'http://0.0.0.0:5000/'
const appInfo = {
  appleId: '1645831238',
  androidId: 'com.wordvector.en',
  wechatId: '',
  name: '向量单词',
  tag: 'worden',
  desc: '回归朴素的 词频、分类、排除法 背单词，力图把它们做透',
  desc_long: '重视 全局感 和 事物间的相关性'
}
export {
  baseUrl,
  appInfo
}
